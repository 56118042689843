import React from 'react';
import Image from 'next/image';
import Button from '@/ui/Button';
import { Card, Row } from '@/ui/primatives';
import contentCardsData from './data.json';
import { ResponsiveFlexContainer } from '@/components/Content/ResponsiveFlexContainer';
import { useRouter } from 'next/navigation';
import Link from 'next/link';

export type TOnboardingContentCards = {
  title: string;
  category: string;
  summary: string;
  imageUrl: string;
  linkUrl: string;
};

const OnboardingContentCards = ({ contentCards = contentCardsData }: { contentCards?: TOnboardingContentCards[] }) => {
  return (
    <Row gap={20} className="mobile-wrap" alignItems='none'>
      {contentCards.map((card, index) => (
        <Link key={index} href={card.linkUrl} style={{ display: 'flex', flex: 1 }}>
          <Card gap={20} style={{ height: '100%' }}>
            <div style={{ width: '100%', height: '150px', position: 'relative' }}>
              <Image src={card.imageUrl} alt={card.title} style={{ objectFit: 'cover' }} fill />
            </div>
            <h6>{card.title}</h6>
            <p>{card.summary}</p>
          </Card>
        </Link>
      ))}
    </Row>
  );
};

export default OnboardingContentCards;
